import VueCookies from 'vue-cookies'

export const UserSourceMixin = {
    methods: {
        getUserSource(query){
          const userSourceCookie = VueCookies.get('user_source')
          if(userSourceCookie != null) return userSourceCookie  
          return this.setUserSource(query)
      },


      setUserSource(query){
        const utm = {}  

        if(query?.utm_source != undefined) utm.utm_source = query.utm_source
        if(query?.utm_medium != undefined) utm.utm_medium = query.utm_medium
        if(query?.utm_campaign != undefined) utm.utm_campaign = query.utm_campaign
        if(query?.utm_afl != undefined) utm.utm_afl = query.utm_afl

        if(Object.keys(utm).length > 0){
            VueCookies.set('user_source' , JSON.stringify(utm), "30d")
            return utm
        }

        return null
      },


    }

}