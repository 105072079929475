<template>
  <div class="page-login" :data-show-banner="showBanner">
    <!-- Logo -->
    <div class="logo">
      <img src="@/assets/img/respondi-logo.png" alt="Respondi logotipo" />
    </div>


    <!-- container -->
    <div class="login-container">
      
      <!-- Promo Banner -->
      <section v-if="showBanner" class="promo-banner">
        <h2 class="promo-title mb4">Você foi convidado a participar do time <strong>{{invite.team}}</strong> 🎉</h2>
        <div class="invite-label-container"><span class="invite-label">Convidado por <strong>{{invite.sender}}</strong></span></div>
        <p class="promo-description mt16"><strong>Entre na sua conta para aceitar o convite</strong>. Se não tiver uma conta, pode criar uma gratuitamente.</p>
      </section>
      
      <!-- Login -->
      <section class="login-form">

        <div class="header">
          <h1 class="title-xl">{{title}}</h1>
          <p class="text-sm mt8">{{description}}</p>
        </div>

        <!-- Signup -->
        <section v-if="action == 'signup'" class="signup">
          <div v-if="errors.signup"  class="message message-negative mt16">
            {{errors.signup}}
          </div>
          <form @submit.prevent="signup()">
            <TextField v-model.trim="credentials.name" :data="form.name" class="mt16" slug="signup_name" /> 
            <TextField v-model.trim="credentials.email" :data="form.email" class="mt16" slug="signup_email" :disabled="inviteBlock" /> 
            <TextField v-model.trim="credentials.phone" v-mask="'(##) #####-####'" :data="form.phone" class="mt16" slug="signup_phone" /> 
            <TextField v-model="credentials.password" :data="form.password" class="mt16" slug="signup_password" /> 
            <Btn type="positive" class="mt16" fullwidth :loading="working.load">Criar conta →</Btn>
            <!-- <router-link :to="{name: 'Forgotpassword'}" class="link text-sm mt16">Esqueceu sua senha?</router-link> -->
          </form>
          <div class="footer">
            <p class="new-user mt8 text-sm">Já possui uma conta? <Btn :link="{name: 'Login'}" type="outline" size="xs" class="ml8">Entrar</Btn></p>
          </div>
        </section>

        <!-- Login -->
        <section v-if="action == 'login' || action == 'first'" class="login">
          <div v-if="nextAction == 'login'" class="message message-positive mt16">
            Sua senha foi alterada com sucesso.
          </div>
          <div v-if="nextAction == 'firstLogin'" class="message message-positive mt16">
            Sua conta foi criada com sucesso. Entre com os dados de acesso.
          </div>
          <form @submit.prevent="login()">
            <TextField v-model.trim="credentials.email" :data="form.email" class="mt16" slug="login_email" :disabled="inviteBlock" /> 
            <TextField v-model="credentials.password" :data="form.passwordLogin" class="mt16" slug="login_password" :error="errors.login" /> 
            <Btn class="mt16" fullwidth :loading="working.load">Entrar →</Btn>
            <router-link :to="{name: 'Forgotpassword'}" class="link text-sm mt16">Esqueceu sua senha?</router-link>
          </form>
          <div class="footer">
            <p class="new-user mt8 text-sm">Não possui uma conta? <Btn :link="{name: 'Signup'}" type="outline" size="xs" class="ml8">Criar conta</Btn></p>
          </div>
        </section>

        <!-- Forgot -->
        <section v-if="action == 'forgot'" class="forgot-password">
          <div v-if="nextAction == 'reset'" class="message message-positive mt16">
            Instruções para criar uma nova senha foram enviadas para o e-mail <strong>{{credentials.email}}</strong>
          </div>

          <form v-if="!nextAction" @submit.prevent="forgot">
            <TextField v-model.trim="credentials.email" :data="form.email" class="mt16" slug="forgot_email" :error="errors.forgot" /> 
            <Btn class="mt16" fullwidth :loading="working.load">Solicitar recuperação</Btn>
            <router-link :to="{name: 'Login'}" class="link text-sm mt16">← Lembrei!</router-link>
          </form>
          <div v-if="!nextAction" class="footer">
            <p class="new-user mt8 text-sm">Não possui uma conta? <Btn :link="{name: 'Signup'}" type="outline" size="xs" class="ml8">Criar conta</Btn></p>
          </div>
        </section>
        
        <!-- Reset -->
        <section v-if="action == 'reset'" class="reset-password">
          <form v-if="!nextAction" @submit.prevent="reset">
            <TextField v-model="credentials.password" :data="form.password" slug="reset_password" class="mt16" />
            <TextField v-model="credentials.password_confirmation" :data="form.password_confirmation" slug="reset_password_confirm" class="mt16" :error="errors.reset" />
            <Btn class="mt16" fullwidth :loading="working.load">Salvar senha</Btn>    
          </form>
        </section>

      </section>
      

    </div>

  </div>
</template>

<script>
import TextField from '@/components/form/TextField.vue';
import Field from '@/components/Field.vue';
import Btn from '@/components/form/Btn.vue';
import {UtilitiesMixin} from '@/mixins/UtilitiesMixin.js';
import {UserSourceMixin} from '@/mixins/UserSourceMixin.js'


const axios = require('axios').default;


export default {
  name: 'Login',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Login',
    meta: [{property: 'og:image', content: 'https://i.imgur.com/cibqXUI.png'}]
  },
  mixins: [UtilitiesMixin, UserSourceMixin],
  components: {
    Field,
    TextField,
    Btn
  },
  props: {
    action: String,
  },

  computed: {
    title() {
      if(this.action == 'forgot') return "Recuperar a senha"
      if(this.action == 'reset') return "Criar senha"
      if(this.action == 'signup') return "Criar conta"
      if(this.action == 'first') return "Pronto!"
      else return "Entrar"
    },
    description() {
      if(this.action == 'forgot') return "Digite seu e-mail de cadastro."
      if(this.action == 'reset') return "Crie uma nova senha forte."
      if(this.action == 'signup') return "Cadastre-se e crie sua conta em 60 segundos."
      if(this.action == 'first') return "Sua conta foi criada com sucesso"
      else return "Entre com seus dados de acesso."
    },

    isCheckout(){
      if(!this.$route.query.checkout || !this.$route.query.plan || this.$route.query.plan == 'free') return false
      return {plan: this.$route.query.plan}
    },

    inviteBlock(){
      if(this.invite?.email) return true
    },

    showBanner(){
      if(this.invite) return "invite"
      return false
    }

  },

  mounted () {
    if(this.action == "first"){
      this.$trackEvent.mixpanel("New Subscription")
      this.$trackEvent.gtm("New Subscription")
      this.nextAction = "firstLogin"
      this.credentials.email = this.$route.query.email
    } 

    this.checkTeamInvite()

    this.getUserSource(this.$route.query)
  },

  data: function () {
    return {
      credentials: {
        email: '',      
        phone: '',      
        password: '',
        password_convirmation: '',
        token: this.$route.query.token,
        invite: null
      },
      invite: null,
      loginFail: null,
      loading: null,
      nextAction: null,
      working: {
        load: false
      },
      errors: {
        login: null,
        forgot: null,
        reset: null,
        signup: null,
      },
      query: null,
      form: {
        name: {
          type: 'text',
          placeholder: 'Nome',
          slug: 'name'
        },
        email: {
          type: 'email',
          placeholder: 'E-mail',
          slug: 'email'
        },
        phone: {
          type: 'phone',
          placeholder: 'Telefone',
          slug: 'phone',
          placeholder: 'Telefone'
        },
        password: {
          type: 'password',
          placeholder: 'Crie uma senha',
          slug: 'password'
        },
        passwordLogin: {
          type: 'password',
          placeholder: 'Sua senha',
          slug: 'password'
        },
        password_confirmation: {
          type: 'password',
          placeholder: 'Confirme a senha',
          slug: 'password_confirmation'
        }
      }
    }
  },

  methods: {
    checkTeamInvite(){
      if(!this.hasTeamInvite()) return

      this.invite = this.hasTeamInvite()
      this.credentials.email = this.invite.email 
    },

    hasTeamInvite(){
      if(this.$route.query.invite) return {token: this.$route.query.invite, email: this.$route.query.email, team: this.$route.query.team, sender: this.$route.query.sender}
      return false
    },


    acceptInvite(){
      axios
      .post(this.$api + 'team/invite/accept/' + this.$route.query.invite)
      .then(response => {})
    },

    reset: function(){
      this.errors.reset = null;
      if(this.credentials.password != this.credentials.password_confirmation){
        this.errors.reset = "As duas senhas não são iguais";
        return;
      }

      if(this.credentials.password.length < 6){
        this.errors.reset = "Deve ter 6 caracteres ou mais.";
        return;
      }

      this.working.load = true;
      const data = {
        email: decodeURI(this.$route.query.u),
        token: this.$route.query.token,
        password: this.credentials.password,
        password_confirmation: this.credentials.password_confirmation,
      };

      axios
        .post(process.env.VUE_APP_API_URL + 'password/reset', data)
        .then(response => {
          if(response.data.success)
            this.nextAction = "login";
            this.working.load = false;
            this.$trackEvent.mixpanel("Password Reset", response.data);
            this.$router.push({ name: 'Login' })
        })

        .catch(error => {
          this.working.load = false;
          if(error.response.data.code == "passwords.token") this.errors.reset = "Este link está exporado ou não é válido. Solicite a recuperação novamente."
          else this.errors.reset = "Ocorreu algum erro, por favor tente novamente."
        })
    },

    signup: function(){
      this.errors.signup = null;
      if(!this.credentials.name || !this.credentials.email || !this.credentials.password){
        this.errors.signup = "Preencha todos os campos."
        return;
      }

      if(this.credentials.password.length < 6){
        this.errors.signup = "Sua senha deve ter 6 caracteres ou mais.";
        return;
      }

      if(this.credentials.phone != null && this.credentials.phone.length > 7){
        this.credentials.phone = "55" + this.credentials.phone.replace(/\D/g, "")
      }




      this.working.load = true;
      const userSource = this.getUserSource(this.$route.query)
      this.credentials = {...this.credentials, ...userSource}
      axios
        .post(process.env.VUE_APP_API_URL + 'users', this.credentials)
        .then(response => {
          if(response.data.success){
            const eventData = { email: this.credentials.email, name: this.credentials.name, utm_source: this.credentials.utm_source, utm_medium: this.credentials.utm_medium, utm_campaign: this.credentials.utm_campaign, utm_afl: this.credentials.utm_afl }
            this.sendWebhook(this.credentials);
            this.nextAction = "firstLogin";
            this.$trackUser.amplitude()
            this.$trackEvent.mixpanel("Sign Up", eventData);
            this.$trackEvent.gtm("Sign Up", eventData);
            this.$trackEvent.fb("244147437773313", "Subscribe", {value: 85,currency: 'brl',});            

            if(this.isCheckout && response.data.user.id){
              this.redirectToCheckout(this.isCheckout.plan, response.data.user.id)
            }

            else{
                this.working.load = false
                if(this.invite) this.$router.push({name: "Login", query: {signup: 'success', invite: this.invite.token}})  
                else this.$router.push({name: "Login", query: {signup: 'success'}})  
            } 

          }
        })
        .catch(error => {
          this.working.load = false;
          if(error.response.data.code == "user.exist") this.errors.signup = "Este e-mail já está cadastrado. Faça login."
          else if(error.response.data.code == "invalid.email"){
            if(error.response.data.data?.did_you_mean) this.errors.signup = "Você quis dizer " + error.response.data.data?.did_you_mean + "?"
            else this.errors.signup = "Informe um e-mail válido. Parece um erro? Entre em contato"
          } 
          else this.errors.signup = "Ocorreu algum erro, por favor tente novamente."
        })
    },

    redirectToCheckout(plan, user){
      let price
      if(plan == 'business') price = process.env.VUE_APP_STRIPE_BUSINESS_PLAN      
      if(plan == 'pro') price = process.env.VUE_APP_STRIPE_PRO_PLAN      
      if(plan == 'solo') price = process.env.VUE_APP_STRIPE_SOLO_PLAN    

      const data = {price, user}

      this.$axios
      .post(process.env.VUE_APP_API_URL + 'subscription/publiccheckoutsession', data)
      .then(response =>{
          window.location.href = response.data.data.url
      })

    },

    forgot: function(){
      this.working.load = true;
      axios
        .post(process.env.VUE_APP_API_URL + 'password/email', {email: this.credentials.email})
        .then(response => {
          if(response.data.success)
            this.nextAction = "reset";
            this.working.load = false;
        })
        .catch(error => {
          this.working.load = false;
          if(error.response.data.code == "password.user") this.errors.forgot = "Este e-mail não está cadastrado."
          else this.errors.forgot = "Ocorreu algum erro, por favor tente novamente."
        })
    },
    
    login: function(){
      this.working.load = true;
      this.errors.login = null;

      if(this.invite) this.acceptInvite()

      axios        
        .post(process.env.VUE_APP_API_URL + 'auth/login', this.credentials)
        .then(response => {
            if(response.data.access_token){
              localStorage.setItem('access_token', response.data.access_token)                            

              axios.post(process.env.VUE_APP_API_URL + 'auth/me')
              .then((response) => {
                localStorage.setItem('user', response.data)                           
                this.$trackEvent.mixpanel("Log in", response.data);   
                this.$trackEvent.gtm("Log in");  
                this.setDatadogUser(response.data)
                
                if(this.$route.query.redirect) this.$router.push({ path: this.$route.query.redirect })           
                else this.$router.push({ name: 'Dashboard' })           
              })
              
            }
            else{
              this.errors.login = "Senha ou e-mail incorreto"
            }            
            
            this.working.load = false;
            this.nextAction = null;


        })    

        .catch(error => {
          console.log(error)
          this.working.load = false;
          this.errors.login = "Senha ou e-mail incorreto";
        })

    },

    setDatadogUser(user){
      DD_RUM.setUser({
        id: user.id,
        name: user.name,
        email: user.email,
        plan: user.plan,
    })

    },

    sendWebhook(formData){
      const url = process.env.VUE_APP_ZAPIER_URL;

      if(!url){
        return null;
      }

      axios.post(url, {
        
          first_name: this.getFirstName(formData.name),
          email: formData.email,
        })
      .then((response) => {
      })

    }
  }


}

</script>

<style lang="sass">

  .page-login
    height: 100%
    background: $cG1
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

  .logo
    padding: $mg $mg 

  .login-container
    display: flex
    background: #ffffff
    padding: $mg
    border-radius: 8px
    width: 90%
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1)
    max-width: 350px
    
    .login-form
      width: 100%


    [data-show-banner="invite"] &
      max-width: 680px

      .login-form
        flex: 1

    .promo-banner
      display: flex
      flex-direction: column
      justify-content: center
      flex: 1
      margin-right: $mgLg
      line-height: 160%

      .invite-label
        +glassStyle($cPrimary)
        display: inline-block
        padding: 4px 8px
        line-height: 100%
        font-size: $fzXxs
        border-radius: 8px

      .promo-title
        font-size: $fzLg
        font-weight: normal
        line-height: 120%

      .promo-description
        font-size: $fzXs

    @media (max-width: 800px)
      flex-direction: column

      .promo-banner
        margin-bottom: $mg
        width: 100%

  
      

  .footer
    margin-top: $mg
    padding-top: $mg
    border-top: 1px solid $cG2

  .message
    font-size: $fzXs
    line-height: 120%
    padding: $mgSm
    border-radius: 4px
    background: $cG1

    &.message-positive
      background: transparentize($cPositive, 0.9)
      color: darken($cPositive, 15%)

    &.message-negative
      background: transparentize($cNegative, 0.9)
      color: darken($cNegative, 15%)


  form
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column


    

</style>
